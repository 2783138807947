<template>
  <a-modal
    v-model="visible"
    class="modal-user create-user"
    title="Usuario"
    :maskClosable="false"
  >
    <a-form :form="form" @submit.prevent="submitAddData">
      <Map
        ref="addressMap"
        @restartControls="restartControls"
        @updateCoordinates="setCoordinates"
        :street="form.getFieldValue('coordinates')"
        :streetNumber="form.getFieldValue('outside_number')"
      />
      <a-form-item label="Dirección" v-if="multipleResults" class="user-data">
        <a-select :default-value="selectedAddress.id" @change="changeAddress">
          <a-select-option
            v-for="(result, index) in addressResults"
            v-bind:key="index"
          >
            {{ getAddress(result) }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item label="Colonia" class="user-data">
        <a-input
          v-decorator="[
            'colonia',
            {
              rules: [
                {
                  required: true,
                  message: 'El campo colonia es requerido.',
                },
              ],
              initialValue: colonia,
            },
          ]"
        />
      </a-form-item>
      <a-form-item label="Referencias" class="user-data">
        <a-input
          v-decorator="[
            'notes',
            {
              rules: [
                {
                  required: true,
                  message: 'El campo referencias es requerido.',
                },
              ],
              initialValue: notes,
            },
          ]"
        />
      </a-form-item>
      <div class="required-field">Es un campo obligatorio</div>
    </a-form>
    <template slot="footer">
      <a-button key="back" class="ant-btn" @click="cancelCreate">
        Cancel
      </a-button>
      <a-button key="create" type="primary" @click="submitAddData">
        Crear
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import Map from "./maps/Map";
import errorM from "@/utils/errors.js";
export default {
  name: "CreateAddress",
  components: {
    Map,
  },
  props: {
    visible: {
      type: Boolean,
      default: true,
    },
    user: {
      type: Object,
      default: null,
    },
    establishment: null,
  },
  data() {
    return {
      addressResults: [],
      form: this.$form.createForm(this, {
        name: "coordinated",
      }),
      data: Object,
      coordinates: {
        type: Object,
        default: null,
      },
      geoAddress: "",
      multipleResults: false,
      reverse: false,
      selectedAddress: {
        id: 0,
        value: [],
      },
      suburb: 1,
    };
  },
  created() {
    this.data = new Object();
    if (this.$store.getters.isSavedXpressAddress) {
      this.coordinates = {
        lat: this.$store.getters.getXpressAddress.address.latitude,
        lng: this.$store.getters.getXpressAddress.address.longitude,
      };

      this.geoAddress = this.$store.getters.getXpressAddress.address.address;
    }
  },
  computed: {
    colonia() {
      return this.$store.getters.isSavedXpressAddress
        ? this.$store.getters.getXpressAddress.address.colonia
        : "";
    },
    notes() {
      return this.$store.getters.isSavedXpressAddress
        ? this.$store.getters.getXpressAddress.address.notes
        : "";
    },
  },
  methods: {
    cancelCreate() {
      const _data = {};

      _data.address = this.form.getFieldsValue();
      _data.user = this.user;
      delete _data.address.coordinates;
      _data.address = {
        ..._data.address,
        latitude: this.coordinates.lat || this.establishment.latitude,
        longitude: this.coordinates.lng || this.establishment.longitude,
        address: this.geoAddress,
      };

      _data.establishment = this.establishment.id;

      this.$store.commit("set_current_save_xpress_address", _data);
      this.$message.info("Nueva dirección en curso guardada", 5);
      this.$router.push("/menu");
    },
    changeAddress(value) {
      this.selectedAddress.id = value;
      this.selectedAddress.value = this.addressResults[value];

      this.$refs.addressMap.updateMarker(
        this.selectedAddress.value.geometry.location.lat,
        this.selectedAddress.value.geometry.location.lng
      );

      this.coordinates = this.selectedAddress.value.geometry.location;
      this.geoAddress = this.selectedAddress.value.formatted_address;

      const _colonia =
        this.selectedAddress.value.address_components.length > this.suburb
          ? this.suburb
          : 0;
      this.form.setFieldsValue({
        colonia:
          this.selectedAddress.value.address_components[_colonia].long_name,
      });
    },
    goBack() {
      this.$emit("cancel");
    },
    getAddress(result) {
      return result.formatted_address;
    },
    async createAddress() {
      if(this.coordinates.lat == this.$store.getters.establishmentLocation.lat
        && this.coordinates.lng == this.$store.getters.establishmentLocation.lng) {
        this.$message.error('El marcador en el mapa se encuentra en la dirección del establecimiento. Usa el buscador en el campo calle o ubicalo manualmente para continuar.');
        return;
      }
      this.data.address = this.form.getFieldsValue();
      this.data.user = this.user;
      delete this.data.address.coordinates;
      this.data.address = {
        ...this.data.address,
        latitude: this.coordinates.lat,
        longitude: this.coordinates.lng,
        address: this.geoAddress,
      };

      this.data.establishment = this.establishment.id;

      const resp = await this.$store.dispatch("post", {
        route: "xpress-address",
        data: this.data,
      });
      if (resp.result) {
        // Remove xpress order from on going task
        this.$store.commit("set_current_save_xpress_address", null);

        this.user.address = this.address;
        this.$store.commit("auth_userXpress", resp.data);
        this.$emit("selectAddress", resp.data.deliveryFee);
      } else {
        this.$notification.error({
          message: "Agregar dirección",
          description: errorM.address[resp.code],
          duration: 5,
        });
      }
    },
    restartControls() {
      this.multipleResults = false;
      this.addressResults = [];

      this.form.setFieldsValue({
        colonia: "",
      });
    },
    setCoordinates(values, address, geoSuburb, results, reverse = false) {
      this.addressResults = results;
      this.multipleResults = false;

      this.reverse = reverse;

      if (reverse) {
        this.suburb = 2;
      }

      // Set default value for address result
      this.selectedAddress.value = this.results ? this.results[0] : [];

      if (results && results.length > 1) {
        this.multipleResults = true;
      }

      // Set default address
      if (results) {
        this.changeAddress(0);
      }
    },
    submitAddData() {
      this.form.validateFields((err) => {
        if (!err) {
          this.createAddress();
        }
      });
    },
  },
};
</script>
